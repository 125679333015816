<template>
  <b-card>
    <div class="d-flex justify-content-between mb-2">
      <b-link to="/">
        <b-button variant="primary">
          <feather-icon icon="PlusIcon" /> Contract
        </b-button>
      </b-link>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-1">
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Sort</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-100"
        />
        <download-excel
          :data="exportItems"
          :fields="exportFields"
          :filename="'Employee'"
          :sheetname="'Employee'"
          class="cursor-pointer ml-1"
        >
          <b-button variant="" size="sm" class="d-flex mr-1 export-btn">
            <feather-icon icon="FileIcon" size="14" class="text-success" />
            <label style="font-size: 14px; margin-left: 5px; cursor: pointer">
              Export
            </label>
          </b-button>
        </download-excel>
      </div>
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Search</label>
        <b-form-input
          id="searchInput"
          v-model="filter"
          size="sm"
          type="search"
          placeholder="Search"
        />
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(employee)="data">
            <span
              v-b-modal="'detail_employee_' + data.value"
              class="text-primary"
            >
              {{ data.value }}
            </span>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            Showing
            {{ perPage > totalRows ? totalRows : perPage }} of
            {{ totalRows }} entries
          </span>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </div>
      </b-col>
    </b-row>

    <b-modal
      v-for="item in items"
      :key="item.id"
      :id="'detail_employee_' + item.name"
      size="lg"
      hide-footer
    >
      <div class="text-center mb-3" style="line-height: 1px">
        <h1 class="mb-2">Detail Employee</h1>
        <span>
          This is data for employee with name <b>{{ item.name }}</b>
        </span>
      </div>
      <form id="update_employee_form">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-form-input
                id="hrcode"
                v-model="item.hrcode"
                type="text"
                placeholder="HR Code"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-card>
</template>

<style>
.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
import axios from "axios";
export default {
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        {
          key: "contract_code",
          label: "Contract Code",
        },
        {
          key: "employee",
          label: "Employee",
        },
        { key: "start_date", label: "Start", sortable: true },
        { key: "end_date", label: "End", sortable: "true" },
      ],
      items: [
        {
          contract_code: "CON-001",
          employee: "John Doe",
          start_date: "2020-01-01",
          end_date: "2020-12-31",
        },
        {
          contract_code: "CON-002",
          employee: "John Doe",
          start_date: "2020-01-01",
          end_date: "2020-12-31",
        },
        {
          contract_code: "CON-003",
          employee: "John Doe",
          start_date: "2020-01-01",
          end_date: "2020-12-31",
        },
        {
          contract_code: "CON-004",
          employee: "John Doe",
          start_date: "2020-01-01",
          end_date: "2020-12-31",
        },
        {
          contract_code: "CON-005",
          employee: "John Doe",
          start_date: "2020-01-01",
          end_date: "2020-12-31",
        },
        {
          contract_code: "CON-006",
          employee: "John Doe",
          start_date: "2020-01-01",
          end_date: "2020-12-31",
        },
        {
          contract_code: "CON-007",
          employee: "John Doe",
          start_date: "2020-01-01",
          end_date: "2020-12-31",
        },
        {
          contract_code: "CON-008",
          employee: "John Doe",
          start_date: "2020-01-01",
          end_date: "2020-12-31",
        },
      ],

      // export excel
      exportFields: {
        "HR Code": "hrcode",
        Name: "name",
        Email: "email",
        Department: "department.name",
        Position: "team.name",
        Status: "is_active",
      },
      exportItems: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    // fetch data
    // this.fetchData();
  },
  methods: {
    fetchData() {
      axios({
        method: "GET",
        url: "https://sw-api.hracademy.id/api/userAll",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("sw_token"),
        },
      })
        .then((response) => {
          this.items = response.data.result.data;
          this.totalRows = this.items.length;

          // export excel
          this.exportItems = response.data.result.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to fetch data",
              variant: "error",
            },
          });
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
